import React from "react";
import Seo from "../../components/seo";
// import { Link } from 'gatsby';

/* START: images import */
import flag from "../../img/flag.jpg";
import you from "../../img/you.png";
/* END: images import */

const Approach = () => {
    return (
        <>
            <Seo title="Práce" />
            <div>
                <article className="article" id="how">
                    <article>
                        <h2 className="line sm">Malý velký tým</h2>
                    </article>
                    <article>
                        <p>Naši klienti (RAF Museum, BMW, Coca Cola a další) jsou převážně z Velké Británie a&nbsp;Německa, kde si již řadu let udržujeme úzké obchodní vazby.</p>
                    </article>
                </article>
                <article className="article" id="extending">
                    <h2 className="line">V čem se u nás můžeš realizovat?</h2>
                    <p>Škála možností je poměrně velká, ale momentálně se to dá hezky shrnout do&nbsp;dvou tradičních&nbsp;kategorií:</p>
                </article>
                <article id="working" className="clearfix">
                    <article className="article">
                        <h3>HTML, CSS</h3>
                        <p>
                            Máš blízko k barvičkám, animacím a vyšperkovanému vzhledu? Bez čistého a přehledného uživatelského rozhraní na jakémkoliv zařízení se u nás neobejde
                            žádná dobrá&nbsp;appka! (A špatné neděláme.)
                        </p>
                    </article>
                    <article className="article">
                        <h3>JavaScript / TypeScript</h3>
                        <p>React, Angular, Vue? Packagers, testing &amp; debugging, build automation? GraphQL, Mongo DB, Express, Node? Přihořívá? U nás už hoří. :)</p>
                    </article>
                </article>
                <section className="halfContentSection">
                    <article className="article" id="planning">
                        <h2 className="line sm">Nabízíme </h2>
                        <img src={flag} alt="Tick! :)" />
                        <ul>
                            <li>nabytí nových znalostí a zkušeností z reálného pracovního prostředí</li>
                            <li>práce na plný nebo částečný úvazek</li>
                            <li>práce na inovativních projektech</li>
                            <li>malý a přátelský kolektiv</li>
                            <li>nadstandardní platební podmínky v závislosti na zkušenostech</li>
                            <li>práce z domova, minimálně každý pátek home office</li>
                            <li>sdílené prostory s úspěšnými startupisty</li>
                            <li>pravidelné měsíční teambuildingové akce</li>
                            <li>jazykové lekce, technické workshopy</li>
                            <li>jóga, pingpong, VR v prostorách</li>
                        </ul>
                        {/*<h3>Budgeting</h3>
                <p>We can work to pre-defined budgets or for ongoing clients we keep track of time using our own time-tracking tool we built called <a href="http://www.marticka.cz/" target="_blank" rel="noopener noreferrer">Marticka</a>. At the end of each month we can give you a full breakdown of the hours spent across each project.</p>*/}
                    </article>

                    <article className="article" id="code">
                        <h2 className="line sm">Hledáme </h2>
                        <div className="img">
                            <img src={you} alt="You" />
                        </div>
                        <ul>
                            <li>tvárného člověka, který se chce učit novým věcem</li>
                            <li>spolehlivost</li>
                            <li>s alespoň základní znalostí angličtiny (na doučování dochází tutoři)</li>
                        </ul>
                    </article>

                    <article className="article" id="experience">
                        <div className="text">
                            <p>
                                Věříme, že tato pracovní zkušenost pomůže cílevědomému koderovi/ce či progamátorce/ovi nastartovat skvělou kariéru. <br />
                                Úrověn znalostí a zkušenost až tak nerozhoduje. Spolehlivost a chuť se učit, ano.{" "}
                            </p>
                            <p>
                                Pište na: <a href="mailto:jobs@code8.cz?subject=Zaujala mě vaše nabídka">jobs@code8.cz.</a>
                            </p>
                        </div>
                    </article>
                </section>
            </div>
        </>
    );
};
export default Approach;
